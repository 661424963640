<script>
import { computed, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2";
import useDynamicTable from "@/helpers/dynamicTable/comprovantes.js";
import useConciliateFileTable from "@/helpers/dynamicTable/documentos.js";
import useConciliateTable from "@/helpers/dynamicTable/tabelaConciliacao.js";
import BrMassActions from "@/components/custom-components/br-mass-actions";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import PermissionsContainer from "@/components/permissions/container.vue";
import uploadFile from "@/components/file/upload-file.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { sendFunctionRequest } from "@/helpers/dynamicTable/sendFunctionRequest";
import { bodyFunctionRequest } from "@/helpers/dynamicTable/bodyFunctionRequest";
import ChatBox from "@/components/chat/chat-box.vue";
import moment from "moment";
import AzureHelper from "../../../../helpers/azure";
import isUnsuportedFileExtension from '@/helpers/isUnsuportedFileExtension';

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const programSchema = {
      sis_programa: props.program_schema,
      sis_tabela: props.program_schema.sis_tabela,
    };
    const data_movimento = moment(
      props.dados.movimento_venda.registros[0].data_movimento
    ).format("DD/MM/YYYY");
    const grid = ref(null);
    const fileGrid = ref(null);
    const conciliateGrid = ref(null);
    var DYNAMIC_TABLE_FUNCTIONS = ref(null);
    var DYNAMIC_FILE_TABLE_FUNCTIONS = ref(null);
    var DYNAMIC_CONCILIATE_TABLE_FUNCTIONS = ref(null);
    var showFileUploadModal = ref(false);
    var showFileModal = ref(false);
    const files = ref([]);
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const conciliate = reactive({
      documento_id: null,
      comprovante_id: null,
    });
    const tipoModal = ref(null);
    const currentIndex = ref(0);
    const currentIdDocumento = ref(null);
    const retorno = ref(props.dados.comprovante.registros);
    const retorno_docs = ref(props.dados.sis_documento.registros);
    const functions = programSchema.sis_programa.sis_funcionalidade;
    const showMessage = ref(false);
    const loading = ref(false);
    const conferimento = ref([]);
    const movimentoMessages = ref([]);
    const movimentoMessagesUsers = ref([]);
    const currentDocuments = ref([])

    onMounted(() => {
      setData(programSchema, retorno_docs.value, retorno.value);
    });

    setMovimentoMessages(props.dados.sis_anotacao.registros);

    async function setData(programSchema, documento_dados, comprovante_dados) {
      document.getElementById("table-wrapper").innerHTML = "";
      DYNAMIC_TABLE_FUNCTIONS.value = useDynamicTable(
        grid,
        programSchema,
        conciliate,
        (value) => (conciliate.comprovante_id = value),
        conciliarDesconciliar,
        (value) => (retorno.value = value),
        conferirDesconferir
      );
      DYNAMIC_TABLE_FUNCTIONS.value.setTableColumns(comprovante_dados);
      document.getElementById("table-file-wrapper").innerHTML = "";
      DYNAMIC_FILE_TABLE_FUNCTIONS.value = useConciliateFileTable(
        fileGrid,
        programSchema,
        conciliate,
        (value) => (conciliate.documento_id = value),
        conciliarDesconciliar
      );
      DYNAMIC_FILE_TABLE_FUNCTIONS.value.setTableColumns(documento_dados);
      currentDocuments.value = documento_dados
    }

    function createTable() {
      document.getElementById("table-conciliate-wrapper").innerHTML = "";
      DYNAMIC_CONCILIATE_TABLE_FUNCTIONS.value = useConciliateTable(
        conciliateGrid,
        programSchema,
        conciliate,
        (value) => (conciliate.comprovante_id = value),
        conciliarDesconciliar
      );
      DYNAMIC_CONCILIATE_TABLE_FUNCTIONS.value.setTableColumns(
        retorno.value ?? props.dados.comprovante.registros
      );
    }

    function getDYNAMIC_TABLE_FUNCTIONS() {
      return DYNAMIC_TABLE_FUNCTIONS;
    }

    function getDYNAMIC_FILE_TABLE_FUNCTIONS() {
      return DYNAMIC_FILE_TABLE_FUNCTIONS;
    }

    function getDYNAMIC_CONCILIATE_TABLE_FUNCTIONS() {
      return DYNAMIC_CONCILIATE_TABLE_FUNCTIONS;
    }

    async function saveFiles() {
      const documentsToUpload = files.value.map(file => {
        return {
          nome_documento: file.name,
          arquivo_base64: null,
          tamanho: file.size,
          descricao: file.descricao,
          id_empresa: props.dados.movimento_venda.registros[0].id_empresa
        }
      });
      
      // const upload = ref([]);

      // // upload.value = await Promise.all(
      // //   files.value.map((f) => {
      // //     return readAsDataURL(f);
      // //   })
      // // );    

      const dados = {
        sis_documento: {
          registros: [...documentsToUpload],
        },
        movimento_venda: {
          registros: [
            {
              id_movimento_venda:
                "" +
                props.dados.movimento_venda.registros[0].id_movimento_venda,
            },
          ],
        },
      };

      loading.value = true;

      await bodyFunctionRequest(
        route.params.targetId,
        dados,
        programSchema.sis_programa.rota_backend +
        functions.find(
          (campo_tabela) =>
            campo_tabela.id_funcionalidade ==
            "dd34d8e4-d993-4e6b-8c99-a411a959550a"
        ).rota_backend, //Incluir Documentos
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then(async (res) => {
          if (!res.data?.status) {
            Swal.fire({
              icon: "error",
              text: res.data.mensagem ? res.data.mensagem : res.data.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
            loading.value = false;
          } else {
            DYNAMIC_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.comprovante.registros
            );
            DYNAMIC_FILE_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.sis_documento.registros
            );
            currentDocuments.value = res.data.retorno.dados.sis_documento.registros
            showFileUploadModal.value = false;
            loading.value = false;

            const documents = []
            documents.push(...res.data.retorno.dados.sis_documento.registros)
            documents.push(...res.data.retorno.dados.comprovante.registros)
            const documentsToUploadAzure = []
            files.value.forEach(file => {
              const filteredDocuments = documents
                .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
              const document = filteredDocuments[0] ?? null
              if (document) {
                document.file = file
                documentsToUploadAzure.push(document)
              }
            })
            const azurehelper = new AzureHelper()
            await azurehelper.uploadDocuments(documentsToUploadAzure)
          }
        });
      });
    }

    function initializeIdDocumento() {
      if (files.value.value[currentIndex.value]) {
        currentIdDocumento.value =
          files.value.value[currentIndex.value].id_documento;
      }
      conciliate.documento_id = currentIdDocumento.value;
      if (tipoModal.value == "Comprovante") {
        conciliate.comprovante_id = retorno.value.find((registro) => {
          return (
            registro?.id_documento ===
            files.value.value[currentIndex.value]?.id_documento
          );
        })?.id_comprovante;
      }
    }

    function desligar() {
      conciliate.comprovante_id = null;
      conciliate.documento_id = null;
    }

    function prevSlide() {
      document.querySelectorAll('.video_player').forEach(vp => {
          vp.pause()
          vp.currentTime = 0
      })
      if (currentIndex.value > 0) {
        currentIndex.value--;
      } else if (currentIndex.value == 0) {
        currentIndex.value = files.value.value.length - 1;
      }
      initializeIdDocumento();
    }

    function nextSlide() {
      document.querySelectorAll('.video_player').forEach(vp => {
          vp.pause()
          vp.currentTime = 0
      })
      if (currentIndex.value < files.value.value.length - 1) {
        currentIndex.value++;
      } else if (currentIndex.value == files.value.value.length - 1) {
        currentIndex.value = 0;
      }
      initializeIdDocumento();
    }

    function isItAPDF(url) {
      const regex = /\.pdf$/;
      return regex.test(url);
    }

    async function sincronizar() {
      const dados = {
        movimento_venda: {
          registros: props.dados.movimento_venda.registros,
        },
      };

      await bodyFunctionRequest(
        route.params.targetId,
        dados,
        programSchema.sis_programa.rota_backend +
        functions.find(
          (campo_tabela) =>
            campo_tabela.id_funcionalidade ==
            "2d3de029-490a-4fc0-b3cd-e7447cb791b5"
        ).rota_backend, //Sincronizar Petros
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then((res) => {
          const data = res.data;
          if (!data.status) {
            Swal.fire({
              icon: "error",
              text: data.mensagem ? data.mensagem : data.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              icon: "success",
              text: "Sucesso!",
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
            DYNAMIC_TABLE_FUNCTIONS.value.updateConfig(
              data.retorno.dados.comprovante.registros
            );
            DYNAMIC_FILE_TABLE_FUNCTIONS.value.updateConfig(
              data.retorno.dados.sis_documento.registros
            );
          }
        });
      });
    }

    function filtro(primeiroArray, segundoArray) {
      var resultado = [];
      var conferido = [];
      primeiroArray == null ? (primeiroArray = []) : primeiroArray;
      for (const primeiroItem of primeiroArray) {
        const segundoItem = segundoArray.find(
          (item) => item.id_documento === primeiroItem.id_documento
        );
        resultado.push(primeiroItem);
        conferido.push(segundoItem);
      }

      conferimento.value.value = conferido;
    }

    async function conferirDesconferir(rota, id) {
      const dados = {
        comprovante: {
          registros: [
            {
              id_comprovante: id,
            },
          ],
        },
      };

      bodyFunctionRequest(
        route.params.targetId,
        dados,
        programSchema.sis_programa.rota_backend + rota,
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then((res) => {
          if (!res.status) {
            Swal.fire({
              icon: "error",
              text: res.mensagem ? res.mensagem : res.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
          } else {
            DYNAMIC_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.comprovante.registros
            );
            DYNAMIC_FILE_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.sis_documento.registros
            );
            tipoModal.value == "Comprovante"
              ? (filtro(
                files.value.value,
                res.data.retorno.dados.comprovante.registros
              ),
                currentIndex.value++,
                files.value.value.length == 0
                  ? (showMessage.value = true)
                  : (showMessage.value = false),
                desligar(),
                initializeIdDocumento())
              : "";
            showFileModal.value
              ? files.value.value.length == 0
                ? (showMessage.value = true)
                : (showMessage.value = false)
              : "";
          }
        });
      });
    }

    async function conciliarDesconciliar(rota) {
      const dados = {
        sis_documento: {
          registros: [
            {
              id_documento: conciliate.documento_id,
            },
          ],
        },
        comprovante: {
          registros: [
            {
              id_comprovante: conciliate.comprovante_id,
            },
          ],
        },
      };

      await bodyFunctionRequest(
        route.params.targetId,
        dados,
        programSchema.sis_programa.rota_backend +
        functions.find(
          (campo_tabela) => campo_tabela.id_funcionalidade === rota
        ).rota_backend,
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then((res) => {
          if (!res.status) {
            Swal.fire({
              icon: "error",
              text: res.mensagem ? res.mensagem : res.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
          } else {
            DYNAMIC_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.comprovante.registros
            );
            DYNAMIC_FILE_TABLE_FUNCTIONS.value.updateConfig(
              res.data.retorno.dados.sis_documento.registros
            );
            desligar();
            files.value.value?.splice(currentIndex.value, 1);
            conferimento.value.value?.splice(currentIndex.value, 1);
            currentIndex.value = 0;
            tipoModal.value == "Documento"
              ? (DYNAMIC_CONCILIATE_TABLE_FUNCTIONS.value.updateConfig(
                res.data.retorno.dados.comprovante.registros
              ),
                initializeIdDocumento())
              : "";
            showFileModal.value
              ? files.value.value.length == 0
                ? (showMessage.value = true)
                : (showMessage.value = false)
              : "";
          }
        });
      });
    }

    // function formatMessageTime(time) {
    //   return moment(new Date(time)).format('DD/MM [às] HH:mm')
    // }

    function setMovimentoMessages(registros) {
      const tempArray = [];
      registros.forEach((registro) =>
        tempArray.push({
          id: registro.id_anotacao,
          user_id: registro.id_usuario_inclusao,
          name: registro.usuario_inclusao,
          message: registro.anotacao,
          time: registro.data_inclusao,
          edited_time: registro.data_alteracao ? registro.data_alteracao : null,
          align:
            JSON.parse(localStorage.getItem("sis_config")).sis_usuario
              .id_usuario == registro.id_usuario_inclusao
              ? "right"
              : "left",
        })
      );

      movimentoMessagesUsers.value = [
        ...new Set(tempArray.map((message) => message.name)),
      ].map((name) => ({ value: name }));
      movimentoMessages.value = tempArray;
    }

    async function handleSubmitMessage(text, message_id = null) {
      let anotacaoData = { anotacao: text };
      if (message_id) {
        anotacaoData = {
          id_anotacao: message_id,
          anotacao: text,
        };
      }
      const rotaBackend = message_id
        ? programSchema.sis_programa.rota_backend +
        programSchema.sis_programa.sis_funcionalidade.find(
          (func) =>
            func.id_funcionalidade == "c03afdc4-9ef4-4545-b23a-2beaa330a9c0"
        ).rota_backend //Editar Comentário
        : programSchema.sis_programa.rota_backend +
        programSchema.sis_programa.sis_funcionalidade.find(
          (func) =>
            func.id_funcionalidade == "4cbc785e-81ca-4f1c-8fcd-a751e3f0535d"
        ).rota_backend; //Incluir Comentário

      const dados = {
        sis_anotacao: {
          registros: [anotacaoData],
        },
        movimento_venda: {
          registros: props.dados.movimento_venda.registros,
        },
      };

      await bodyFunctionRequest(
        route.params.targetId,
        dados,
        rotaBackend,
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then((res) => {
          setMovimentoMessages(res.data.retorno.dados.sis_anotacao.registros);
        });
      });
    }

    async function handleDeleteMessage(message) {
      const dados = {
        sis_anotacao: {
          registros: [{ id_anotacao: message.id }],
        },
        movimento_venda: {
          registros: props.dados.movimento_venda.registros,
        },
      };

      await bodyFunctionRequest(
        route.params.targetId,
        dados,
        programSchema.sis_programa.rota_backend +
        programSchema.sis_programa.sis_funcionalidade.find(
          (campo_tabela) =>
            campo_tabela.id_funcionalidade ==
            "779adf9e-1da7-4984-b787-9b0d74469dd8"
        ).rota_backend, //Excluir Comentário
        props.dados.movimento_venda.registros[0].id_movimento_venda
      ).then((data) => {
        sendFunctionRequest(data).then((res) => {
          setMovimentoMessages(res.data.retorno.dados.sis_anotacao.registros);
        });
      });
    }
    
    const getCurrentDocumentData = computed(() => {
      return currentDocuments.value.find(f => f.id_documento == files.value.value[currentIndex.value]?.id_documento)
    })

    function onKeyUp(event) {
      if (event.key === 'ArrowRight') {
          nextSlide()
      }
      if (event.key === 'ArrowLeft') {
          prevSlide()
      }
      if(event.key === 'Escape'){
        showFileModal.value = false
      }
    }

    return {
      isUnsuportedFileExtension,
      getCurrentDocumentData,
      handleDeleteMessage,
      handleSubmitMessage,
      movimentoMessages,
      grid,
      fileGrid,
      conciliateGrid,
      programSchema,
      getDYNAMIC_TABLE_FUNCTIONS,
      DYNAMIC_TABLE_FUNCTIONS,
      getDYNAMIC_FILE_TABLE_FUNCTIONS,
      DYNAMIC_FILE_TABLE_FUNCTIONS,
      DYNAMIC_CONCILIATE_TABLE_FUNCTIONS,
      getDYNAMIC_CONCILIATE_TABLE_FUNCTIONS,
      defaultOptions: { animationData: animationData },
      PermissionsContainer,
      showFileUploadModal,
      showFileModal,
      files,
      saveFiles,
      isItAPDF,
      data_movimento,
      routerBack: () => {
        store.commit("frameworkNavegador/setBeingReturned", true);
        window.history.length > 1 ? router.go(-1) : router.push("/");
      },
      sincronizar,
      createTable,
      tipoModal,
      prevSlide,
      nextSlide,
      currentIndex,
      currentIdDocumento,
      initializeIdDocumento,
      retorno,
      retorno_docs,
      conferirDesconferir,
      functions,
      conciliarDesconciliar,
      showMessage,
      desligar,
      loading,
      conferimento,
      filtro,
      onKeyUp,
    };
  },
  watch: {
    showFileModal(value) {
      if (this.tipoModal == "Documento") {
        if (value) {
          this.createTable();
          this.retorno
            ? this.DYNAMIC_CONCILIATE_TABLE_FUNCTIONS.updateConfig(this.retorno)
            : "";
          document.addEventListener('keyup', this.onKeyUp)
        }
      } else if (this.tipoModal == "Comprovante") {
        this.conferimento.value?.length === 0
          ? (this.showMessage = true)
          : (this.showMessage = false);
          document.addEventListener('keyup', this.onKeyUp)
      }
    },
  },
  components: {
    BrMassActions,
    uploadFile,
    Lottie,
    ChatBox,
  },
};
</script>

<template>
  <div class="position-relative">
    <div class="card">
      <div class="card-body">
        <div class="col">
          <div class="row">
            <div class="d-flex justify-content-between mt-2">
              <div class="card-body">
                <span class="text-muted">Empresa:</span>
                <p style="font-weight: 700; font-size: 13px">
                  {{ this.dados.movimento_venda.registros[0].empresa }}
                </p>
                <span class="text-muted">Data do Movimento:</span>
                <p style="font-weight: 700; font-size: 13px">
                  {{ data_movimento }}
                </p>
              </div>
              <div class="card-body">
                <span class="text-muted">Operador:</span>
                <p style="font-weight: 700; font-size: 13px">
                  {{ this.dados.movimento_venda.registros[0].usuario }}
                </p>
                <span class="text-muted">Número do Movimento:</span>
                <p style="font-weight: 700; font-size: 13px">
                  {{ this.dados.movimento_venda.registros[0].numero_movimento }}
                </p>
              </div>
              <div class="col">
                <a @click="sincronizar"
                  class="btn btn-outline-dark btn-label shadow-sm mt-1 mt-md-3 mx-sm-0 mx-md-3 col-8"
                  title="Sincronizar">
                  <i class="ri-arrow-left-right-line align-bottom me-1 label-icon" />
                  Sincronizar Petros
                </a>
                <a @click="routerBack"
                  class="btn btn-outline-dark btn-label shadow-sm mt-1 mt-md-3 mx-sm-0 mx-md-3 col-8" title="Voltar">
                  <i class="ri-arrow-go-back-fill align-bottom me-1 label-icon" />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card pb-4">
      <div class="row">
        <div class="col-lg">
          <div class="row">
            <div class="my-2">
              <div class="px-3 d-flex justify-content-between">
                <BrMassActions
                  v-if="DYNAMIC_FILE_TABLE_FUNCTIONS && fileGrid"
                  :programSchema="programSchema.sis_programa"
                  :tableFunctions="DYNAMIC_FILE_TABLE_FUNCTIONS"
                  :docsTableFunctions="DYNAMIC_FILE_TABLE_FUNCTIONS"
                  :grid="fileGrid" 
                  :movimento_venda="'Documento'"
                  :showUploadModal="() => (showFileUploadModal = true)"
                  :arquivos="(value) => (files.value = value)"
                  :showFileModal="() => (showFileModal = true)"
                  :tipoModal="(value) => (tipoModal = value)"
                  :createTable="createTable"
                  :conciliateTableFunctions="DYNAMIC_CONCILIATE_TABLE_FUNCTIONS"
                />
                <h4 class="mt-2">Documentos</h4>
              </div>
            </div>
          </div>
          <div class="card-body pt-1 position-relative pb-5">
            <div id="table-file-wrapper" />
            <div ref="table_notfound_component" id="table_notfound_component" class="position-absolute w-100"
              style="top: 55px; left: 0; display: none">
              <div class="text-center">
                <Lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                  :height="75" :width="75" />
                <h5 class="mt-2">Nenhum Registro Encontrado</h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="my-2">
              <div class="px-3 d-flex justify-content-between">
                <BrMassActions v-if="DYNAMIC_TABLE_FUNCTIONS && grid" :programSchema="programSchema.sis_programa"
                  :tableFunctions="DYNAMIC_TABLE_FUNCTIONS" :docsTableFunctions="DYNAMIC_FILE_TABLE_FUNCTIONS"
                  :grid="grid" :movimento_venda="'Comprovante'" :arquivos="(value) => (files.value = value)"
                  :showFileModal="() => (showFileModal = true)" :tipoModal="(value) => (tipoModal = value)"
                  :conciliateTableFunctions="DYNAMIC_CONCILIATE_TABLE_FUNCTIONS" :dados="retorno"
                  :conferimento="(value) => (conferimento.value = value)" />
                <h4 class="mt-2">Comprovantes</h4>
              </div>
            </div>
          </div>
          <div class="card-body pt-1 position-relative pb-5">
            <div id="table-wrapper" />
            <div ref="table_notfound_component" id="table_notfound_component" class="position-absolute w-100"
              style="top: 55px; left: 0; display: none">
              <div class="text-center">
                <Lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                  :height="75" :width="75" />
                <h5 class="mt-2">Nenhum Registro Encontrado</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal id="generateOtpModal" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
    v-model="showFileUploadModal">
    <uploadFile :updateFile="(data) => (files = data)"  :state="showUploadFileModal"/>
    <div class="d-grid gap-2">
      <div v-if="loading" class="text-center">
        <b-spinner variant="success" />
      </div>
      <b-button v-else class="btn btn-sm btn-success" @click="saveFiles()">
        Salvar
      </b-button>
    </div>
  </b-modal>

  <b-modal id="generateOtpModal" @show="initializeIdDocumento" fade centered size="xl" hide-header hide-footer
    v-model="showFileModal">
    <div class="modal-content" style="
        width: 95vw;
        height: 95vh;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      ">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          {{ tipoModal }}s
        </h5>
      </div>
      <div class="modal-body">
        <div class="row" v-if="showMessage == true && conferimento.value?.length == 0">
          <lottie colors="primary:#405189,secondary:#08a88a" :options="defaultOptions" :height="180" :width="180" />
          <div class="mt-4 pt-2 text-center">
            <h5>Não existem documentos a serem revisados!</h5>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-8">
            <div id="carouselExampleControlsNoTouching" class="carousel slide position-relative" data-bs-touch="false"
              data-bs-interval="false">
              <div class="carousel-inner" role="listbox">
                <div v-for="(file, index) in files.value"
                  :key="file.id_documento" class="carousel-item"
                  :class="index == currentIndex ? ' active' : ''"
                >
                  <template v-if="!getCurrentDocumentData?.link_upload ||  getCurrentDocumentData.link_upload && file.tamanho_arquivo == getCurrentDocumentData.tamanho">
                    <div class="border-dashed border p-2 mx-1 text-center d-flex justify-content-center align-items-center" style="height: 65vh" v-if="isUnsuportedFileExtension(file.nome_documento)">
                      <p>Visualização indisponível</p>
                    </div>
                    <iframe v-else-if="isItAPDF(file.nome_documento)"
                      class="d-block img-fluid mx-auto"
                      style="height: 65vh; width: 100vw"
                      :src="file.link_azure + '#view=fitH'"
                    />
                    <div v-else-if="['ogg', 'mp4', 'mov'].includes(file.nome_documento.split('.').at(-1))"
                        class="d-flex justify-content-center align-items-center">
                        <video class="video_player mx-auto" style="height: 65vh; width: 100%"
                            controls>
                            <source :src="file.link_azure"
                            type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <img v-else class="d-block img-fluid mx-auto" style="height: 65vh; width: auto" :src="file.link_azure" />
                    <p class="text-center mt-2">
                      {{ file.nome_documento }}
                    </p>
                  </template>
                  <template v-else>
                    <div class="d-flex justify-content-center align-items-center" style="height:60vh;">
                        <p class="text-center mt-2 text-primary">
                            <strong>{{  getCurrentDocumentData.usuario_inclusao }}</strong> ainda não finalizou o upload deste arquivo.
                        </p>
                    </div>
                    <p class="text-center mt-2">
                        {{ file.nome_documento }}
                    </p>
                  </template>
                </div>
              </div>
              <div class="d-flex justify-content-between bg-light slider-container">
                <a @click="prevSlide" 
                    class="carousel-control-prev slider ri-arrow-left-s-line text-white"
                    href="#carouselExampleControlsNoTouching" 
                    role="button" 
                    data-bs-slide="prev"
                    v-b-tooltip.hover.top="'<- (seta esquerda)'"></a>
                <a @click="nextSlide"
                    class="carousel-control-next slider ri-arrow-right-s-line text-white"
                    href="#carouselExampleControlsNoTouching"
                    role="button"
                    data-bs-slide="next"
                    v-b-tooltip.hover.top="'-> (seta direita)'"></a>
              </div>
            </div>
          </div>
          <div v-if="tipoModal == 'Documento'" class="col-4">
            <div class="card-body pt-1 position-relative pb-5" style="max-height:60vh;overflow-y:scroll;">
              <div id="table-conciliate-wrapper" />
              <div ref="table_notfound_component" id="table_notfound_component" class="position-absolute w-100"
                style="top: 55px; left: 0; display: none">
                <div class="text-center">
                  <Lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                    :height="75" :width="75" />
                  <h5 class="mt-2">Nenhum Registro Encontrado</h5>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-4">
            <div v-if="tipoModal == 'Comprovante' && conferimento.value">
              <div class="card border card-border-dark mt-3">
                <div class="d-flex justify-content-between mt-2 text-center">
                  <div class="col">
                    <p style="font-weight: 700; font-size: 15px">Dados</p>
                  </div>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Cliente:</span>
                  <span style="font-weight: 700; font-size: 13px">
                    {{ conferimento.value[currentIndex]?.codigo_cliente }} -
                    {{ conferimento.value[currentIndex]?.nome_cliente }}
                  </span>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Forma:</span>
                  <span class="me-2" style="font-weight: 700; font-size: 13px">
                    {{
                    conferimento.value[currentIndex]
                      ?.denominacao_forma_recebimento
                  }}
                  </span>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Valor Recebido:</span>
                  <span style="font-weight: 700; font-size: 13px">
                    {{
                      conferimento.value[currentIndex]?.valor_recebido.toFixed(2)
                    }}
                  </span>
                </div>
                <div v-if="conferimento.value[currentIndex]?.valor_troco != 0">
                  <div class="d-flex text-start mb-2">
                    <span class="text-muted me-1 ms-2">Valor Troco:</span>
                    <span style="font-weight: 700; font-size: 13px">
                      {{
                    conferimento.value[currentIndex]?.valor_troco.toFixed(2)
                  }}
                    </span>
                  </div>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Valor Venda:</span>
                  <span style="font-weight: 700; font-size: 13px">
                    {{
                      (conferimento.value[currentIndex]?.valor_recebido -
                        conferimento.value[currentIndex]?.valor_troco).toFixed(2)
                    }}
                  </span>
                </div>
              </div>
              <div v-if="conferimento.value[currentIndex]?.id_emitente_cheque != null
                    " class="card border card-border-dark mt-3">
                <div class="d-flex mt-2 mb-2 text-center">
                  <div class="col">
                    <span style="font-weight: 700; font-size: 15px">Cheque</span>
                  </div>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Emitente:</span>
                  <span style="font-weight: 700; font-size: 13px">
                    {{ conferimento.value[currentIndex]?.nome_emitente_cheque }}
                  </span>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <div class="d-flex text-start mb-2">
                      <span class="text-muted me-1 ms-2">Banco:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{ conferimento.value[currentIndex]?.codigo_banco }} -
                        {{ conferimento.value[currentIndex]?.nome_banco }}
                      </span>
                    </div>
                    <div class="d-flex text-start mb-2">
                      <span class="text-muted me-1 ms-2">Número:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{ conferimento.value[currentIndex]?.numero_cheque }}
                      </span>
                    </div>
                    <div class="d-flex text-start mb-2">
                      <span class="text-muted me-1 ms-2">Agência/CC:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{
                    conferimento.value[currentIndex]?.agencia_cheque
                  }}/{{
                      conferimento.value[currentIndex]
                        ?.conta_corrente_cheque
                    }}
                      </span>
                    </div>
                    <div class="d-flex text-start">
                      <span class="text-muted me-1 ms-2">Predatado:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{ conferimento.value[currentIndex]?.predatado_cheque }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex text-start mb-2">
                  <span class="text-muted me-1 ms-2">Observação:</span>
                  <span class="me-2" style="font-weight: 700; font-size: 13px">
                    {{ conferimento.value[currentIndex]?.observacao }}
                  </span>
                </div>
              </div>
              <div v-if="conferimento.value[currentIndex]?.nsu" class="card border card-border-dark mt-3">
                <div class="d-flex mb-2 mt-2 text-center">
                  <div class="col">
                    <span style="font-weight: 700; font-size: 15px">Cartão</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col d-flex align-items-center">
                    <div class="d-flex text-start">
                      <span class="text-muted me-1 ms-2">NSU:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{ conferimento.value[currentIndex]?.nsu }}
                      </span>
                    </div>
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="d-flex text-start">
                      <span class="text-muted me-1 ms-2">Parcelas:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{
                    conferimento.value[currentIndex]?.quantidade_parcela
                  }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="conferimento.value[currentIndex]?.documento_vale_frete"
                class="card border card-border-dark mt-3">
                <div class="d-flex mt-2 mb-2 text-center">
                  <div class="col">
                    <span style="font-weight: 700; font-size: 15px">Vale Frete</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex text-start">
                    <span class="text-muted me-1 ms-2">Tipo:</span>
                    <span style="font-weight: 700; font-size: 13px">
                      {{
                    conferimento.value[currentIndex]?.titulo_tipo_vale_frete
                  }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="d-flex text-start">
                      <span class="text-muted me-1 ms-2">Documento:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{
                      conferimento.value[currentIndex]?.documento_vale_frete
                    }}
                      </span>
                    </div>
                    <div class="d-flex text-start mb-2">
                      <span class="text-muted me-1 ms-2">Peso:</span>
                      <span style="font-weight: 700; font-size: 13px">
                        {{
                      conferimento.value[
                        currentIndex
                      ]?.peso_chegada.toFixed(3)
                    }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center">
        * Pressione a tecla Control (Ctrl) e gire o Scroll (rodinha) do mouse
        para aumentar/diminuir o zoom no documento.
      </p>
      <div class="modal-footer border-top">
        <div class="col">
          <button @click="
                  (showFileModal = !showFileModal), desligar(), (tipoModal = '')
                    " class="btn btn-primary mt-3" v-b-tooltip.hover.top="'ESC'">
            Sair
          </button>
        </div>
        <div v-if="tipoModal == 'Comprovante' && showMessage == false" class="col text-end">
          <button @click="
                    conferirDesconferir(
                      conferimento.value[currentIndex]?.conferido
                        ? functions.find(
                          (campo_tabela) =>
                            campo_tabela.id_funcionalidade ===
                            '220fb561-c912-4b5a-8d7d-bf0bdb258ca0'
                        ).rota_backend
                        : functions.find(
                          (campo_tabela) =>
                            campo_tabela.id_funcionalidade ===
                            'b0b4acf2-4e22-42cb-a43a-77d97daffc06'
                        ).rota_backend,
                      retorno.find((registro) => {
                        return (
                          registro.id_documento ===
                          files.value[currentIndex].id_documento
                        );
                      }).id_comprovante
                    )
                    " :class="conferimento?.value?.[currentIndex]?.conferido
                    ? 'btn btn-danger mt-3'
                    : 'btn btn-success mt-3'
                    ">
            {{
                    conferimento?.value?.[currentIndex]?.conferido
                      ? "Desconferir"
                      : "Conferir"
                  }}
          </button>
          <button @click="
                  initializeIdDocumento(),
                    conciliarDesconciliar('68ec7d3d-e6cc-449b-8829-e636a812b0c4')
                    " class="btn btn-danger ms-3 mt-3">
            Desconciliar
          </button>
        </div>
      </div>
    </div>
  </b-modal>

  <div class="chat-container">
    <ChatBox :chatMessagesData="movimentoMessages" :onSubmitNewMessage="handleSubmitMessage"
      :onDeleteMessage="handleDeleteMessage" />
  </div>
</template>

<style scoped>
.slider {
  background-color: #000;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: inherit;
}

.slider-container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}
#table-conciliate-wrapper {
  width: fit-content;
}
#table-conciliate-wrapper > .gridjs-wrapper {
  overflow-x: hidden;
}

iframe {
  zoom: 1;
}
</style>
