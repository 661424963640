<script>
import CustomInput from '@/components/custom_input/index.vue';
import { ref, watch } from 'vue';
import uploadFile from '@/components/file/upload-file.vue';
import isUnsuportedFileExtension from '@/helpers/isUnsuportedFileExtension';

export default {
    props: {
        formType: {
            type: String,
            requerid: true,
        },
        formModifierRespostaFormulario: {
            type: Object,
            required: false,
        },
        perguntas: {
            type: Array,
            required: false,
        },
        groupNames: {
            type: Array,
            required: false,
        },
        tableName: {
            type: String,
            required: false,
        },
        updateFilesToBeSaved: {
            type: Function,
            required: true,
        },
        filesToBeSaved: {
            type: Array,
            required: true,
        },
        supportDocuments: {
            type: Boolean,
            required: true,
            default: false
        },
        deleteDocumento: {
            type: Function,
            required: false
        },
        loadingFileUpload: {
            type: Boolean,
            required: false,
            default: false
        },
        autoClose: {
            type: Boolean,
            required: false,
            default: false
        },
        closeNow: {
            type: Boolean,
            required: false,
            default: false
        },
        loadingShowLinks: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        const activeTab = ref(null);
        const showUploadFileModal = ref(false)
        const files = ref([])
        const target_id_pergunta_formulario = ref(null)

        const setShowUploadFileModal = (id_pergunta_formulario) => {
            files.value = []
            showUploadFileModal.value = true
            target_id_pergunta_formulario.value = id_pergunta_formulario
        }
        const attachFiles = () => {
            props.updateFilesToBeSaved(target_id_pergunta_formulario.value, files.value)
            if(props.autoClose){
                showUploadFileModal.value = false
            }
        }
        const deleteFile = (id_pergunta_formulario, target_index) => {
            const target = props.filesToBeSaved.find(ftbs => ftbs.id_pergunta_formulario === id_pergunta_formulario)
            target.files.splice(target_index, 1)
        }
        const generateURL = (file) => {
            if (file.type != 'application/pdf') {
                let fileSrc = URL.createObjectURL(file)
                setTimeout(() => {
                    URL.revokeObjectURL(fileSrc)
                }, 1000)
                return fileSrc
            }
            else {
                let fileSrc = new String(URL.createObjectURL(file))
                setTimeout(() => {
                    URL.revokeObjectURL(fileSrc)
                }, 1000)
                return fileSrc
            }
        }

        watch(() => props.closeNow, (value) => {
            if(value){
                showUploadFileModal.value = false
            }
        })
        
        return {
            isUnsuportedFileExtension,
            activeTab,
            showUploadFileModal,
            files,
            setShowUploadFileModal,
            attachFiles,
            deleteFile,
            generateURL,
            pdfRegex: ref(/\.pdf$/)
        }
    },
    components: {
        CustomInput,
        uploadFile
    }
};
</script>

<template>
    <template class="row" v-if="formModifierRespostaFormulario">
        <div v-for="group, index in groupNames" :key="index"
            :class="[group.id ? 'border border-primary border-dashed mt-5' : 'mt-2', 'pt-2 pb-3 mx-1 position-relative px-2']">
            <div v-if="group.id" style="position:absolute;top:-40px;left:-2px;min-width:100px;height:40px;"
                class="border p-2 bg-primary">
                <h5 class="m-0 text-light">{{ group.titulo }}</h5>
            </div>

            <template v-for="pergunta in perguntas.filter(p => p.sis_pergunta.id_categoria_pergunta == group.id)"
                :key="group.id+pergunta.sis_pergunta.id_pergunta_formulario">
                <div class="col-12 col-md-8">
                    <div class="mt-3 ms-md-3 d-flex align-items-start">
                        <button type="button" v-if="supportDocuments"
                            @click="setShowUploadFileModal(pergunta.sis_pergunta.id_pergunta_formulario)"
                            class="border me-2 btn btn-ghost-secondary btn-icon btn-sm fs-20"
                            v-b-tooltip.hover.top="'Anexar Documentos'" style="margin-top:26px;">
                            <i class="link-success ri-attachment-2 align-bottom"></i>
                        </button>
                        <div class="flex-1">
                            <CustomInput :formState="formModifierRespostaFormulario"
                                :formType="tableName == 'sis_usuario_respostas_formulario' && !pergunta.sis_pergunta.permite_alteracao_resposta ? 'show' : formType"
                                :columnSchema="formModifierRespostaFormulario.getColumnById(pergunta.sis_pergunta.id_pergunta_formulario)"
                                :showLabel="true" :textArea="pergunta.sis_pergunta.tipo_pergunta == 20"
                                :supportRepeatedInput="true" 
                            />                        
                            <b-collapse :visible="activeTab === pergunta.sis_pergunta.id_pergunta_formulario"
                                :id="'flush-' + pergunta.sis_pergunta.id_pergunta_formulario" class="bg-light border-bottom"
                                accordion="my-accordion" role="tabpanel">
                                <div class="px-2 py-1">
                                    <div class="p-2 mt-1">
                                        <div v-for="file, index in filesToBeSaved.find(f => f.id_pergunta_formulario == pergunta.sis_pergunta.id_pergunta_formulario)?.files"
                                            :key="file.id_documento || file.name+index" class="preview-card border rounded mb-2 position-relative form-control">
                                            <div class="d-flex flex-column flex-lg-row align-items-lg-center flex-wrap  p-2">
                                                <div class="mx-3">
                                                    <div class="border-dashed border p-2 mx-1 text-center d-flex justify-content-center align-items-center" style="height: 65vh" v-if="isUnsuportedFileExtension(file.nome_documento)">
                                                        <p>Visualização indisponível</p>
                                                    </div>
                                                    <iframe v-else-if="pdfRegex.test(file.nome_documento ?? file.name)"
                                                        class="d-block img-fluid mx-auto" style="width: 100%;max-width:200px;"
                                                        :src="(file.link_azure ?? generateURL(file)) + '#view=fitH'" />
                                                    <div v-else-if="['ogg', 'mp4', 'mov'].includes(file.name?.split('.').at(-1) ?? file.nome_documento.split('.').at(-1))"
                                                        class="d-flex justify-content-center align-items-center">
                                                        <video v-if="file.link_azure" class="video_player mx-auto" style="width: 100%;max-width:200px;"
                                                            controls>
                                                            <source :src="file.link_azure"
                                                            type="video/mp4">
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        <img v-else class="img-thumbnail me-2" alt="100x200" height="100px" width="200" src="@/assets/images/video_file_type.svg" data-holder-rendered="true" />
                                                    </div>
                                                    <img v-else class="d-block img-fluid mx-auto" style="width: 100%;max-width:200px;" :src="file.link_azure ?? generateURL(file)" />
                                                </div>
                                                <div class="flex-grow-1" style="min-width:max-content;">
                                                    <div class="pt-1">
                                                        <h5 class="fs-11 mb-1" data-dz-name="">
                                                            {{ file.nome_documento || file.name }}
                                                        </h5>
                                                        <p v-if="file.size" class="fs-9 text-muted mb-0" data-dz-size="">
                                                            <strong>{{ ((file.size / 1024) / 1000).toFixed(2) }}</strong> MB
                                                        </p>
                                                        <p class="fs-9 text-muted mb-0" data-dz-size="">
                                                            <textarea rows="2" class="form-control form-control-sm disabled border-dashed" readonly disabled
                                                                placeholder="" v-model="file.descricao" />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="flex-shrink-0 mt-2 ms-lg-3 position-absolute" style="top:-15px;right:-10px;">
                                                    <b-button-group>
                                                        <button type="button"
                                                            @click="file.id_documento ? deleteDocumento(pergunta.sis_pergunta.id_pergunta_formulario, index) : deleteFile(pergunta.sis_pergunta.id_pergunta_formulario, index)"
                                                            class="bg-white btn btn-white border rounded-circle btn-icon btn-sm fs-20"
                                                            v-b-tooltip.hover.top="'Excluir Documento'">
                                                            <i class="link-danger ri-delete-bin-5-fill align-bottom"></i>
                                                        </button>
                                                    </b-button-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                            <div class="">
                                <div class="d-flex position-relative">
                                    <button type="button"
                                        class="flex gap-2 align-items-center btn bg-light rounded-bottom accordion-button p-2 text-info"
                                        v-b-toggle="'flush-' + pergunta.sis_pergunta.id_pergunta_formulario"
                                        v-b-tooltip.hover.left="'Ver documentos em anexo'"
                                        @click="() => activeTab = activeTab === pergunta.sis_pergunta.id_pergunta_formulario ? null : pergunta.sis_pergunta.id_pergunta_formulario"
                                        >
                                            <div v-if="loadingShowLinks" class="text-muted text-center">
                                                Carregando documentos...
                                            </div>
                                            <div v-else-if="!filesToBeSaved.find(f => f.id_pergunta_formulario == pergunta.sis_pergunta.id_pergunta_formulario)?.files.length > 0"
                                                class="text-muted text-center"
                                            >
                                                Nenhum documento anexado...
                                            </div>
                                            <div v-else>
                                                <span class="mt-1 fs-10 badge rounded-pill bg-info">
                                                    {{ filesToBeSaved.find(f => f.id_pergunta_formulario == pergunta.sis_pergunta.id_pergunta_formulario)?.files.length || 0 }}
                                                </span>
                                                Documentos em anexo
                                            </div>
                                            <span>
                                                <i :class="['mdi fs-18', activeTab === pergunta.sis_pergunta.id_pergunta_formulario ? 'mdi-chevron-up': 'mdi-chevron-down']"></i>
                                            </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <b-modal id="generateOtpModal" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
            v-model="showUploadFileModal">
            <uploadFile :updateFile="(data) => (files = data)" :state="showUploadFileModal" />
            <div class="d-grid gap-2">
                <div v-if="loadingFileUpload" class="text-center">
                    <b-spinner variant="success" />
                </div>
                <b-button v-else class="btn btn-sm btn-success" @click="attachFiles()">
                    Anexar
                </b-button>
            </div>
        </b-modal>
    </template>
</template>