<script>
/**
 * Form Layout Container
 * Request the program schema then pass to target component as a prop
 **/

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { ref, onMounted, computed, watch } from 'vue';
import BrPageLoading from "@/components/custom-components/br-page-loading.vue"
import { ApiService } from "../../helpers/api.service";
import Swal from "sweetalert2";
import putPrimaryKeyInFirstIndex from "../../helpers/putPrimaryKeyInFirstIndex";
import { useStore } from "vuex";
import frameworkNavegador from "../formularios/framework/navegador.vue"
import MassActionsModals from '@/components/massActionsModals/index';

import suprimentosMovimentacaoGestaoCompra from "../formularios/suprimentos/movimentacao/gestao_compra.vue"
import administracao_sistemaCustomizacaoFuncionalidade from "../formularios/administracao_sistema/customizacao/funcionalidade.vue"
import administracao_sistemaCustomizacaoArvore from "../formularios/administracao_sistema/customizacao/arvore.vue"
import administracao_sistemaCustomizacaoPrograma from "../formularios/administracao_sistema/customizacao/programa.vue"
import administracao_sistemaCustomizacaoModulo from "../formularios/administracao_sistema/customizacao/modulo.vue"
import administracao_sistemaCustomizacaoTabela from "../formularios/administracao_sistema/customizacao/tabela.vue"
import administracao_sistemaGerenciamentoEmpresa from "../formularios/administracao_sistema/gerenciamento/empresa.vue"
import administracao_sistemaGerenciamentoUsuario from "../formularios/administracao_sistema/gerenciamento/usuario.vue"
import administracao_sistemaCustomizacaoAuditoria from "../formularios/administracao_sistema/gerenciamento/auditoria.vue"
import administracao_sistemaCustomizacaoListaValor from "../formularios/administracao_sistema/customizacao/lista_valor.vue"
import administracao_sistemaCustomizacaoOpcaoListaValor from "../formularios/administracao_sistema/customizacao/opcao_lista_valor.vue"
import administracao_sistemaGerenciamentoGrupoEmpresa from "../formularios/administracao_sistema/gerenciamento/grupo_empresa.vue"
import administracao_sistemaGerenciamentoUsuarioGrupo from "../formularios/administracao_sistema/gerenciamento/usuario_grupo.vue"
import administracao_sistemaChamadoChamado from "./administracao_sistema/chamado/chamado.vue"
import administracao_sistemaChamadoTipoChamado from "./administracao_sistema/chamado/tipo_chamado.vue"
import administracao_sistemaChamadoDepartamento from "./administracao_sistema/chamado/departamento.vue"
import corporativoCadastrosPessoa from "../formularios/corporativo/cadastros/pessoa.vue"
import suprimentosTabelasTipoUnidadeMedida from "../formularios/suprimentos/tabelas/tipo_unidade_medida"
import suprimentosTabelasCategoriaItem from "../formularios/suprimentos/tabelas/categoria_item"
import suprimentosTabelasUnidadeMedida from "../formularios/suprimentos/tabelas/unidade_medida"
import automacaoMovimentacaoMovimento_venda from "../formularios/automacao/movimentacao/movimento_venda.vue"
import financeiroMovimentacaoContas_receber from "../formularios/financeiro/movimentacao/contas_receber.vue"
import financeiroTabelasBanco from "../formularios/financeiro/tabelas/banco.vue"
import financeiroMovimentacaoLotePagamento from "../formularios/financeiro/movimentacao/lote_pagamento.vue"
import financeiroTabelasContaFinanceira from "../formularios/financeiro/tabelas/conta_financeira.vue"
import financeiroTabelasTipoCobranca from "../formularios/financeiro/tabelas/tipo_cobranca.vue"
import financeiroTabelasIdentificadorSistemaPagamentoBrasileiro from "../formularios/financeiro/tabelas/identificador_sistema_pagamento_brasileiro.vue"
import financeiroAreaPixPixRecebidos from "../formularios/financeiro/area_pix/pix_recebidos.vue"
import financeiroMovimentacaoDebitoDiretoAutorizado from "../formularios/financeiro/movimentacao/debito_direto_autorizado.vue"
import financeiroMovimentacaoContasPagar from '../formularios/financeiro/movimentacao/contas_pagar.vue'
import suprimentosCadastrosItem from "../formularios/suprimentos/cadastros/item.vue"
import suprimentosMovimentacaoSolicitacaoCompra from "../formularios/suprimentos/movimentacao/solicitacao_compra.vue"
import suprimentosMovimentacaoCotacaoCompra from "../formularios/suprimentos/movimentacao/cotacao_compra.vue"
import suprimentosMovimentacaoPedidoCompra from "../formularios/suprimentos/movimentacao/pedido_compra.vue"
import administracao_sistemaDashboardPainel from "../formularios/administracao_sistema/dashboard/painel.vue"
import administracao_sistemaDashboardConsulta from "../formularios/administracao_sistema/dashboard/consulta.vue"
import InsightGerencimentoFormulario from "../formularios/insight/gerenciamento/formulario.vue"
import InsightGerencimentoFormularioResposta from "../formularios/insight/gerenciamento/formulario_resposta.vue"
import InsightCadastrosCategoriaPergunta from "../formularios/insight/cadastros/categoria_pergunta.vue"
import InsightCadastrosPergunta from "../formularios/insight/cadastros/pergunta.vue"
import InsightCadastrosListaOpcoes from "../formularios/insight/cadastros/lista_opcoes.vue"

export default {
    setup() {
        const route = useRoute()
        /** Force data update on route change */
        // eslint-disable-next-line
        onBeforeRouteUpdate((to, from, next) => {
            const newParams = to.params
            store.commit('programForm/setTitle', 'Sistema Apoio')
            resolveFormType(to)
            program_schema.value = null;
            title.value = '';
            pageLoading.value = true;
            setProgramSchema(newParams, to.query)
            next()
        })
        onMounted(() => {
            setProgramSchema(params, query);
        })

        const params = route.params
        const query = route.query

        const store = useStore();
        store.commit('programForm/setTitle', 'Sistema Apoio')
        resolveFormType(route)

        const active = ref(true)
        const title = ref()
        const pageLoading = ref(true)
        const program_schema = ref(null)
        const dados = ref(null)
        const registro_id = ref(null)
        const component_name = computed(() => {
            if(store.state.programForm.formType === 'navigation'){
                return program_schema.value?.formulario_navegacao
            }else{
                return program_schema.value?.formulario_edicao
            }
        })

        watch(() => route.name, async (newValue, oldValue) => {
            if(newValue !== oldValue){
                if(route.name == 'dynamic-module-tree-program'){
                    pageLoading.value = true;
                    active.value = false;
                    const newParams = route.params
                    const newQuery = route.query
                    await setProgramSchema(newParams, newQuery)
                    active.value = true;
                }
            }
        })

        async function setProgramSchema(params, query){
            const body = {
                id_programa: params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: 'obtem_dados_programa_usuario'
            }

            if(store.state.programForm.formType !== 'navigation'){
                body.navegador = false   
            }
            if(query.registro_id) {
                body.id = query.registro_id
                body.quantidade_registros = 1
                registro_id.value = query.registro_id
            }

            const res = await ApiService({
                method: 'POST',
                url: `/program`,
                data: body,
                headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
            })
            .then(res => {
                const data = res.data;

                if(!data.status){
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                    return false;
                }

                return data.retorno;
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    text: error.response.data.mensagem ?? error.message,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
                return false;
            })

            pageLoading.value = false;
            if(!res) return
            
            program_schema.value = res.sis_programa;
            dados.value = res.dados;
            program_schema.value.sis_tabela.sis_campo_tabela.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)

            program_schema.value.sis_tabela.sis_campo_tabela.map(col => {
                if(col.id_tabela_detalhe){
                    col.dados_tabela_detalhe.sis_tabela.sis_campo_tabela.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao) 
                }
            })

            program_schema.value.sis_tabela = putPrimaryKeyInFirstIndex(program_schema.value.sis_tabela);

            store.commit('programForm/setTitle', program_schema.value.denominacao)
            
            store.state.programForm.formType !== 'navigation' ? 
            store.commit('programForm/setFormType', store.state.programForm.formType) : 
            store.commit('programForm/setFormType', 'navigation') 
        
            title.value = program_schema.value.denominacao;
        }

        function resolveFormType(route){
            if(route.query.registro_id || route.query.tipo){
                route.query.tipo == 'edicao' ? store.commit('programForm/setFormType', 'edition') : 
                route.query.tipo == 'visualizacao' ? store.commit('programForm/setFormType', 'show') :
                route.query.tipo == 'criacao' ? store.commit('programForm/setFormType', 'creation') :
                store.commit('programForm/setFormType', 'navigation')
            }else{
                store.commit('programForm/setFormType', 'navigation')
            }
        }

        return {
            title,
            pageLoading,
            program_schema,
            dados,
            registro_id,
            component_name,
            active,
        }
    },
    methods: {
        component_exists(name){
           return this.$options.components[name] ? true : false;
        }
    },
    components: {
        Layout,
        PageHeader,
        BrPageLoading,
        MassActionsModals,

        ['/framework/navegador']: frameworkNavegador,
        ['/suprimentos/movimentacao/gestao_compra']: suprimentosMovimentacaoGestaoCompra,
        ['/administracao_sistema/customizacao/funcionalidade']: administracao_sistemaCustomizacaoFuncionalidade,
        ['/administracao_sistema/customizacao/arvore']: administracao_sistemaCustomizacaoArvore,
        ['/administracao_sistema/customizacao/programa']: administracao_sistemaCustomizacaoPrograma,
        ['/administracao_sistema/customizacao/modulo']: administracao_sistemaCustomizacaoModulo,
        ['/administracao_sistema/customizacao/tabela']: administracao_sistemaCustomizacaoTabela,
        ['/administracao_sistema/customizacao/opcao_lista_valor']: administracao_sistemaCustomizacaoOpcaoListaValor,
        ['/administracao_sistema/gerenciamento/empresa']: administracao_sistemaGerenciamentoEmpresa,
        ['/administracao_sistema/gerenciamento/usuario']: administracao_sistemaGerenciamentoUsuario,
        ['/administracao_sistema/gerenciamento/auditoria']: administracao_sistemaCustomizacaoAuditoria,
        ['/financeiro/movimentacao/lote_pagamento']: financeiroMovimentacaoLotePagamento,
        ['/administracao_sistema/customizacao/lista_valor']: administracao_sistemaCustomizacaoListaValor,
        ['/administracao_sistema/gerenciamento/grupo_empresa']: administracao_sistemaGerenciamentoGrupoEmpresa,
        ['/administracao_sistema/gerenciamento/usuario_grupo']: administracao_sistemaGerenciamentoUsuarioGrupo,
        ['/administracao_sistema/chamado/chamado']: administracao_sistemaChamadoChamado,
        ['/administracao_sistema/chamado/tipo_chamado']: administracao_sistemaChamadoTipoChamado,
        ['/administracao_sistema/chamado/departamento']: administracao_sistemaChamadoDepartamento,
        ['/corporativo/cadastros/pessoa']: corporativoCadastrosPessoa,
        ['/suprimentos/tabelas/tipo_unidade_medida']: suprimentosTabelasTipoUnidadeMedida,
        ['/suprimentos/tabelas/categoria_item']: suprimentosTabelasCategoriaItem,
        ['/suprimentos/tabelas/unidade_medida']: suprimentosTabelasUnidadeMedida,
        ['/automacao/movimentacao/movimento_venda']: automacaoMovimentacaoMovimento_venda,
        ['/financeiro/movimentacao/contas_receber']: financeiroMovimentacaoContas_receber,
        ['/financeiro/tabelas/banco']: financeiroTabelasBanco,
        ['/financeiro/tabelas/conta_financeira']: financeiroTabelasContaFinanceira,
        ['/financeiro/tabelas/tipo_cobranca']: financeiroTabelasTipoCobranca,
        ['/financeiro/area_pix/pix_recebidos']: financeiroAreaPixPixRecebidos,
        ['/financeiro/movimentacao/debito_direto_autorizado']: financeiroMovimentacaoDebitoDiretoAutorizado,
        ['/financeiro/movimentacao/contas_pagar']: financeiroMovimentacaoContasPagar,
        ['/financeiro/tabelas/identificador_sistema_pagamento_brasileiro']: financeiroTabelasIdentificadorSistemaPagamentoBrasileiro,
        ['/suprimentos/cadastros/item']: suprimentosCadastrosItem,
        ['/suprimentos/movimentacao/solicitacao_compra']: suprimentosMovimentacaoSolicitacaoCompra,
        ['/suprimentos/movimentacao/cotacao_compra']: suprimentosMovimentacaoCotacaoCompra,
        ['/suprimentos/movimentacao/pedido_compra']: suprimentosMovimentacaoPedidoCompra,
        ['/administracao_sistema/dashboard/painel']: administracao_sistemaDashboardPainel,
        ['/administracao_sistema/dashboard/consulta']: administracao_sistemaDashboardConsulta,
        ['/insight/gerenciamento/formulario']: InsightGerencimentoFormulario,
        ['/insight/gerenciamento/formulario_resposta']: InsightGerencimentoFormularioResposta,
        ['/insight/cadastros/categoria_pergunta']: InsightCadastrosCategoriaPergunta,
        ['/insight/cadastros/pergunta']: InsightCadastrosPergunta,
        ['/insight/cadastros/lista_opcoes']: InsightCadastrosListaOpcoes,
    }
};
</script>
    
<template>
    <Layout>
        <div class="position-relative">
            <BrPageLoading v-if="pageLoading"/>
            <div v-if="active" :class="pageLoading ? 'd-none' : ''">
                <PageHeader :title="title" :items="[]" :program_schema="program_schema"/>
                <component 
                    :is="component_exists(component_name) ? component_name : null"
                    :program_schema="program_schema"
                    :registro_id="registro_id"
                    :dados="dados"
                />

                <MassActionsModals />
            </div>
        </div>
    </Layout>
</template>