<script>
import { useStore } from 'vuex'
import useProgram from '../../../../composables/program'
import { onMounted, provide } from 'vue'
import TabelaDetalhe from '@/components/tabela_detalhe/index.vue'
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
    inModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSuccess: {
      type: Function,
      required: false,
    },
    formType_: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()
    const formType = props.formType_ ? props.formType_ : store.state.programForm.formType;
    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id
    }
    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading,
      resetMainAndDetailTableForms
    } = useProgram(formType, programa)

    onMounted(() => {
      setProgramForm()
    })

    provide('resetMainAndDetailTableForms', resetMainAndDetailTableForms)
    provide('id_empresa', programa.dados?.debito_direto_autorizado.registros[0]?.id_empresa ?? null)

    return {
      formId: uuidv4(),
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
    }
  },
  components: {
    TabelaDetalhe,
    FormBotoes,
    CustomInput
  }

}
</script>
<template>
  <div class="row">
    <div class="card">
      <div class="my-2 mb-3">
        <template v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
        <form v-else
          @submit.prevent="handleFormSubmit($event, [], null, false, false, null, this.$props.onSuccess)"
          :id="formId"
          class="needs-validation"
          novalidate
        >
        <ul class="nav nav-tabs mb-3" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab"
                  :href="'#arrow-program' + program_schema.id_programa + program_schema.id_tabela"
                  role="tab" aria-selected="true">
                  <span>{{ program_schema.sis_tabela.denominacao }}</span>
              </a>
          </li>

          <li v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela"
              class="nav-item">
              <a class="nav-link" data-bs-toggle="tab"
                  :href="'#arrow-' + program_schema.id_programa + detail_table.schema.id_tabela"
                  role="tab">
                  <span class="">{{ detail_table.title }}</span>
              </a>
          </li>
        </ul>

          <!-- Tab pane base form -->
          <div class="tab-content">
            <div class="tab-pane active" :id="'arrow-program' + program_schema.id_programa + program_schema.id_tabela" role="tabpanel">

              <div class="border p-3 mb-0">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('nome_empresa')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('situacao')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('cnpj_cpf_nome_credor_devedor')"
                        :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('cnpj_cpf_nome_cedente')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('data_vencimento')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('data_limite_pagamento')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('linha_digitavel')" allowCopy="ri-barcode-line"
                        :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('valor_titulo')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema="baseFormModifier.getColumnByName('numero')" :showLabel='true' />
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('observacao')" :showLabel="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div 
              v-for="detail_table in sis_detail_table" 
              :key="detail_table.schema.id_tabela"
              :id="'arrow-' + program_schema.id_programa + detail_table.schema.id_tabela"
              class="tab-pane"
              role="tabpanel"
            >
              <div class="border p-3 mb-0">
                <TabelaDetalhe :formType="formType" :table="detail_table" :mainTableFormModifier="baseFormModifier"
                  @addRow="addNewDetailTableForm" @removeRow="removeDetailTableForm"
                  :programSchema="this.$props.program_schema" />
              </div>
            </div>
          </div>

          <FormBotoes :formType="formType" :loading="btnloading" :inModal="inModal" />
        </form>
      </div>
    </div>
  </div>
</template>
