<script>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import flatPickr from "vue-flatpickr-component";
import { Portuguese as PortugueseLocale } from "flatpickr/dist/l10n/pt";

import "flatpickr/dist/flatpickr.css";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ContasPagarComponent",
  components: {
    Multiselect,
    flatPickr
  },
  setup() {
    const store = useStore();
    const formType = store.state.programForm.formType;
    const id_conta_liquidacao = ref(null);
    const id_tipo_cobranca = ref(null);

    const listFinancialAccount = computed(
      () => store.state.contasPagar.listFinancialAccounts
    );
    const listTypeOfCharge = computed(
      () => store.state.contasPagar.listOfTypeCharge
    );

    const dataAtual = new Date().toISOString()
    const apenasData = dataAtual.substring(0,10)
    const data_pagamento = ref(apenasData);
    const dateConfig = reactive({
      altFormat: "d/m/Y",
      altInput: true,
      dateFormat: "Y-m-d",
      locale: PortugueseLocale,
      allowInput: true,
      defaultDate: data_pagamento.value,
    })

    function getValuesFromForm() {
      return {
        id_conta_liquidacao: id_conta_liquidacao.value,
        id_tipo_cobranca: id_tipo_cobranca.value,
        data_pagamento: data_pagamento.value,
      }
    }

    function clearData() {
      id_conta_liquidacao.value = null
      id_tipo_cobranca.value = null
      data_pagamento.value = apenasData

      store.commit('contasPagar/setListFinancialAccounts', []);
      store.commit('contasPagar/setListOfTypeCharge', []);
    }

    return {
      formType,
      id_conta_liquidacao,
      id_tipo_cobranca,
      listFinancialAccount,
      listTypeOfCharge,
      data_pagamento,
      dateConfig,
      getValuesFromForm,
      clearData
    };
  },
};
</script>
<template>
  <div>
    <div>
      <b-row>
        <b-col>
          Conta
          <Multiselect
            autocomplete="one-time-code"
            v-model="id_conta_liquidacao"
            :close-on-select="true"
            :searchable="true"
            :options="listFinancialAccount"
            :no-options-text="'A lista está vazia'"
            :track-by="'titulo'"
            :label="'titulo'"
            :value-prop="'id'"
            :classes="{
              wrapper: 'd-flex align-items-center justify-content-between',
            }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          Data de Pagamento
          <flat-pickr
            v-model="data_pagamento"
            :config="dateConfig"
            placeholder="Data"
            class="form-control flatpickr-input"
          />
        </b-col>
        <b-col cols="12" lg="6">
          Cobrança
          <Multiselect
            autocomplete="one-time-code"
            v-model="id_tipo_cobranca"
            :close-on-select="true"
            :searchable="true"
            :options="listTypeOfCharge"
            :track-by="'titulo'"
            :label="'titulo'"
            :value-prop="'id'"
            :classes="{
              wrapper: 'd-flex align-items-center justify-content-between',
            }"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>