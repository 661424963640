<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { makeResizable } from "@/helpers/makeResizable";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-luxon";
import Vue3TagsInput from "vue3-tags-input";
Vue3ChartJs.registerGlobalPlugins([ChartDataLabels]);

import {
  GroupedDinamicData,
  backgroundColorWithOpacity,
} from "../configuracao_consulta/funcionalidadeschart";

import { Grid, h } from "gridjs";
import { onMounted, watch, ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import ptBR from "../../helpers/dynamicTable/ptBrLanguage";
export default {
  components: {
    Vue3ChartJs,
    Vue3TagsInput,
    Multiselect,
  },
  props: {
    allConfigComponent: {
      type: Object || undefined,
    },
    otherCardstoBeFiltered: {
      type: Function,
    },
    isFiltered: {
      type: Boolean,
    },
  },
  setup(props) {
    const Charts = ref(null);
    const searchonTable = ref([]);
    const store = useStore();
    const indexSelect = ref([]);
    const selectedData = ref(0);
    const gridValue = ref(null);
    const indexSelected = ref(0);
    const filtroHighLight = ref([]);
    const dataFiltered = ref([]);
    const allinstances = ref([]);
    const footerDefault = ref([]);
    let rowsSelecteds = ref([]);

    let chartHeight = ref("inherit");
    let chartElementWidth = ref(0);
    let titleChartWidth = ref(0);
    let pageChartSelectWidth = ref(0);
    let prev = document.querySelector("#PreviewPainel");
    const tableRef = ref(null);
    const debounce = (func, delay = 300) => {
      let timer;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(func.bind(this, ...arguments), delay);
      };
    };
    function Position(a) {
      switch (a) {
        case 1:
          a = `text-left justify-content-start`;
          break;
        case 2:
          a = `text-center justify-content-center`;
          break;
        case 3:
          a = `text-right justify-content-end`;
          break;
      }
      return a;
    }
    const statemode = computed(() => {
      return store.state.layout.mode;
    });
    const selected = ref([props.allConfigComponent?.paginacao?.[0]]);
    watchEffect(() => {
      selected.value = props.allConfigComponent?.paginacao?.[0].map((a) => a);
      selectedData.value =
        props.allConfigComponent?.paginacao?.[0]?.length || 0;
    });
    function Width() {
      chartElementWidth.value = document?.querySelector(
        ".chart-all" + props?.allConfigComponent?.id
      )?.offsetWidth;
      pageChartSelectWidth.value =
        document?.querySelector(
          ".group-selectedFilterChart" + props?.allConfigComponent?.id
        )?.offsetWidth + 30;
      titleChartWidth.value = document?.querySelector(
        ".title-chart-dashboard" + props?.allConfigComponent?.id
      )?.offsetWidth;
    }
    const setTable = (config) => {
      const gridContainer = document.getElementById(config.id);
      //const tableContainer = document.querySelector(`#${config.id} .gridjs-wrapper`);

      tableRef.value =
        gridContainer?.getElementsByClassName("gridjs-container");
      const colunas = [];
      const columns = config.colunas;
      columns.map((v, i) => {
        colunas.push({
          id: v.nome + v.agregador_total,
          name: h(
            "div",
            {
              className: " text-center h-100",
              style: {
                textAlign: "center",
                padding: "0px 2em",
              },
            },
            v.titulo || v.nome
          ),
          formatter: (cell) => {
            const vcell =
              v.agregador > 1
                ? (v.prefixo + " " || "") +
                  Number(cell).toLocaleString("pt-BR", {
                    minimumFractionDigits: v.casadec,
                    maximumFractionDigits: v.casadec,
                  }) +
                  (" " + v.sufixo || "")
                : cell;

            return h(
              "div",
              {
                id: i,
                style: "white-space:nowrap;",
                className: `d-flex align-items-center ${Position(
                  v.posicao_columns
                )}`,
              },
              vcell
            );
          },

          sort: {
            compare: (a, b) => {
              const isDate = (value) => {
                const regexDateTime = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;
                const regexDate = /^\d{2}\/\d{2}\/\d{4}$/;
                return regexDateTime.test(value) || regexDate.test(value);
              };
              const convertToDate = (dateString) => {
                const parts = dateString.split("/");
                return new Date(parts[2], parts[1] - 1, parts[0]);
              };

              // Função auxiliar para verificar se um valor é um número
              const isNumber = (value) => {
                return !isNaN(parseFloat(value)) && isFinite(value);
              };

              if (isDate(a) && isDate(b)) {
                // Se ambos forem datas, converta para objetos Date e compare
                const dateA = convertToDate(a);
                const dateB = convertToDate(b);
                if (dateA > dateB) {
                  return 1;
                } else if (dateB > dateA) {
                  return -1;
                } else {
                  return 0;
                }
              } else if (isNumber(a) && isNumber(b)) {
                // Se ambos forem números, converta para números e compare
                return a * 10000 - b * 10000;
              } else {
                // Caso contrário, faça uma comparação de string padrão
                return String(a).localeCompare(String(b));
              }
            },
            enabled: true,
            multiColumn: true,
          },
        });
      });

      dataFiltered.value = config.dados
        .map((obj) => config.colunas.map((v) => obj[v.titulo || v.nome]))
        .slice(0, -1);
      footerDefault.value = config.dados
        .map((obj) =>
          config.colunas.map((v) => obj[v.titulo || v.nome] ?? null)
        )
        .pop();
      const haFooter = footerDefault.value.some((a) => a != null);
      function createFooter2() {
        if (haFooter) {
          //const larguraColunasHeader = calcularLarguraColunas(); // Função para calcular a largura das células do cabeçalho
          const tds = footerDefault.value.map((cell, index) => {
            const formcell = Number(cell) == Number ? Number(cell) : cell;
            const formatad = formcell
              ? `${columns[index].prefixo || ""} ${formcell.toLocaleString(
                  "pt-BR",
                  {
                    minimumFractionDigits: columns[index].casadec,
                    maximumFractionDigits: columns[index].casadec,
                  }
                )} ${columns[index].sufixo || ""}`
              : "";
            const td = document.createElement("td");
            const div = document.createElement("div");
            div.textContent = formatad;
            (div.className = `d-flex align-items-center ${Position(
              columns[index].posicao_columns
            )}`),
              td.appendChild(div);
            return td;
          });

          const tr = document.createElement("tr");
          tr.className = "p-0 m-0 fw-semibold ";
          tds.forEach((td) => tr.appendChild(td));

          // Adicione o tr ao tfoot de cada coluna
          const tfoot = document.createElement("tfoot");
          tfoot.className = "sticky-bottom gridjs-foote py-2 bg-light";
          tfoot.appendChild(tr);

          // Remova qualquer tfoot existente antes de adicionar o novo
          const existingFooters = gridContainer?.querySelectorAll("tfoot");
          existingFooters.forEach((footer) => footer.remove());

          // Adicione o tfoot ao gridContainer
          gridContainer.querySelector("table").appendChild(tfoot);
        }
      }
      gridValue.value = new Grid({
        columns: colunas,
        resizable: true,
        height: `${gridContainer.offsetHeight - 15}px`,
        data: dataFiltered.value,
        className: {
          thead: "bg-light",
          td: "p-0 m-0 h-0 w-0",
          table: "position-relative",
          th: "position-relative",
        },
        sort: true,
        language: ptBR,
        pagination: {
          summary: false,
          nextButton: false,
          prevButton: false,
          limit: 38,
        },
        style: {
          td: {
            cursor: "pointer",
            overflow: "hidden",
            "white-space": "nowrap",
          },
          th: {
            width: "18px",
          },
        },
      });

      gridContainer.innerHTML = "";
      gridValue.value.render(document.getElementById(config.id));
      gridValue.value.on("cellClick", (event, cell, column, row) => {
        const clickedElement = event.target.parentNode.parentNode;

        const clickedRow = clickedElement;

        if (
          !clickedRow.classList.contains("gridjs-tr-selected") &&
          clickedRow.tagName != "TBODY"
        ) {
          clickedRow.classList.add("gridjs-tr-selected");
          rowsSelecteds.value.push(row.cells);
        } else if (
          clickedRow.classList.contains("gridjs-tr-selected") &&
          clickedRow.tagName != "TBODY"
        ) {
          clickedRow.classList.remove("gridjs-tr-selected");
          const indexToRemove = rowsSelecteds.value.findIndex(
            (selectedRow) => selectedRow.cells === row.cells
          );
          rowsSelecteds.value.splice(indexToRemove, 1);
        }
        const parameters = [];
        const relevantColumns = gridValue.value.config.columns.map((a) => a.id);
        const colSearchFiltered = columns
          .filter((o) => o.agregador_total == 1)
          .map((a) => ({
            id: a.nome + a.agregador_total,
            nome: a.nome,
          }));

        relevantColumns.forEach((v, i) => {
          const matchingColumn = colSearchFiltered.find((col) => col.id === v);
          if (matchingColumn) {
            const parameterData = {
              parametro: matchingColumn.nome,
              valores_parametro: rowsSelecteds.value.map(
                (selectedRow) => selectedRow.map((a) => a)[i].data
              ),
              id_consulta: config.id_consulta,
            };
            parameters.push(parameterData);
          }
        });
        localStorage.setItem(
          "filter_chart_dicamic",
          JSON.stringify(parameters)
        );
      });

      gridValue.value.on("ready", () => {
        createFooter2();

        const targetTable = ref(
          document.querySelectorAll(`.gridjs-wrapper .gridjs-table`)
        );
        const targetTables = document.querySelectorAll(
          ` .gridjs-wrapper .gridjs-table`
        );
        targetTables.forEach((table) => {
          const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
              if (entry.contentRect && entry.contentRect.width > 0) {
                makeResizable(targetTable);
                resizeObserver.unobserve(table);
              }
            }
          });

          resizeObserver.observe(table);
        });
      });

      const handleResize = debounce(() => {
        gridValue.value
          .updateConfig({
            data: dataFiltered.value,
            height: `${gridContainer.offsetHeight - 15}`,
            fixedHeader: true,
          })
          .forceRender();
      }, 300);

      prev?.addEventListener("click", handleResize);
      gridValue.value
        .updateConfig({
          data: dataFiltered.value,
          fixedHeader: true,
        })
        .forceRender();
    };
    const handreResizeChart = debounce(() => {
      chartHeight.value = "100%";
    }, 1);

    prev?.addEventListener("click", handreResizeChart);

    onMounted(() => {
      if (props.allConfigComponent.id_type == 6) {
        setTable(props.allConfigComponent);
      }

      if (![1, 6].includes(props.allConfigComponent.id_type)) {
        selected.value = props.allConfigComponent?.paginacao?.[0].map((a) => a);
        selectedData.value =
          props.allConfigComponent?.paginacao?.[0]?.length || 0;
        allinstances.value.push(props.allConfigComponent.instance);
        chartElementWidth.value = document?.querySelector(
          ".chart-all" + props?.allConfigComponent?.id
        )?.offsetWidth;
        pageChartSelectWidth.value =
          document?.querySelector(
            ".group-selectedFilterChart" + props?.allConfigComponent?.id
          )?.offsetWidth + 30;
        titleChartWidth.value = document?.querySelector(
          ".title-chart-dashboard" + props?.allConfigComponent?.id
        )?.offsetWidth;
        updateChartOptions(Charts.value, statemode.value);
      }
    });

    function activeSearhTable(tag) {
      searchonTable.value = tag;

      if (searchonTable.value.length > 0) {
        dataFiltered.value = props.allConfigComponent.dados
          .map((obj) =>
            props.allConfigComponent.colunas.map((v) => obj[v.titulo || v.nome])
          )
          .slice(0, -1)
          .filter((item) => {
            return searchonTable.value.every((tag) => {
              return item.some((value) =>
                String(value).toLowerCase().includes(tag.toLowerCase())
              );
            });
          });
        const groupedToFooter = props.allConfigComponent.dadosOriginais.filter(
          (item) => {
            return searchonTable.value.every((tag) => {
              return props.allConfigComponent.colunas.some((column) => {
                const value = item[column.nome];
                return String(value).toLowerCase().includes(tag.toLowerCase());
              });
            });
          }
        );
        const NewFooterAgrouped = GroupedDinamicData(
          groupedToFooter,
          props.allConfigComponent.colunas.filter(
            (o) => o.agregador_total == 1
          ),
          props.allConfigComponent.colunas.filter((o) => o.agregador_total > 1),
          props.allConfigComponent.colunas.map((a) => a.agregador),
          props.allConfigComponent.colunas.map((a) => a.agregador_total),
          props.allConfigComponent.id_type
        );

        const newFooter = NewFooterAgrouped.map((obj) =>
          props.allConfigComponent.colunas.map(
            (v) => obj[v.titulo || v.nome] ?? null
          )
        ).pop();
        footerDefault.value = newFooter;
      } else {
        dataFiltered.value = props.allConfigComponent.dados
          .map((obj) =>
            props.allConfigComponent.colunas.map((v) => obj[v.titulo || v.nome])
          )
          .slice(0, -1);
        footerDefault.value = props.allConfigComponent.dados
          .map((obj) =>
            props.allConfigComponent.colunas.map(
              (v) => obj[v.titulo || v.nome] ?? null
            )
          )
          .pop();
      }

      const newtableHeight = tableRef.value[0].offsetHeight - 30;
      const maxTableHeight = tableRef.value[0].parentNode.offsetHeight;
      gridValue.value
        .updateConfig({
          data: dataFiltered.value,
          height: `${newtableHeight} px`,
          style: {
            container: {
              height: maxTableHeight - 30,
            },
          },
        })
        .forceRender();
    }

    function extractIndexPagination() {
      const sss = props.allConfigComponent.mydata.findIndex((element, i) => {
        if (selected.value.join() === element.pag.join()) {
          let isn = i;
          return isn == -1 ? 0 : isn;
        }
      });
      indexSelected.value = sss != -1 ? sss : 0;
    }
    const updateChartOptions = (chartInstance, mode) => {
      // Mudancas de cores Em Black/White
      chartInstance.options.scales.x.ticks.color =
        mode == "light" ? "#494949" : "#C9C4C4";
      chartInstance.options.scales.x.grid.color =
        mode == "light" ? "#C4C4C4" : "#656565";
      chartInstance.options.scales.right.ticks.color =
        mode == "light" ? "#494949" : "#C9C4C4";
      chartInstance.options.scales.right.grid.color =
        mode == "light" ? "#C4C4C4" : "#656565";
      chartInstance.options.scales.left.ticks.color =
        mode == "light" ? "#494949" : "#C9C4C4";
      chartInstance.options.scales.left.grid.color =
        mode == "light" ? "#C4C4C4" : "#656565";
      chartInstance.options.plugins.legend.labels.color =
        mode && mode == "light" ? "#494949" : "#C9C4C4";
      chartInstance.data.datasets.forEach((val) => {
        val.datalabels.color = mode == "light" ? "black" : "white";
        val.datalabels.backgroundColor =
          mode == "light"
            ? backgroundColorWithOpacity(val.datalabels.borderColor, 0.4, -60)
            : backgroundColorWithOpacity(val.datalabels.borderColor, 0.4, 60);
      });
      chartInstance.update();
    };

    watch(
      () => statemode.value,
      (newVal) => {
        if (![1, 6].includes(props.allConfigComponent.id_type)) {
          updateChartOptions(Charts.value, newVal);
        }
      }
    );
    const htmlLegendPlugin = {
      id: "htmlLegend",
      afterInit(chart) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)();
          this.height += 20;
        };
      },
    };
    //document.addEventListener('click', )

    function resetStyleChart(chart) {
      chart.value.data.datasets.forEach((dataset) => {
        if (dataset.clickedIndexes && dataset.originalColors) {
          dataset.backgroundColor = dataset.originalColors.slice();
          dataset.borderWidth = dataset.originalborderWidth.slice();
          dataset.borderColor = dataset.originalBorderColors.slice();
          dataset.clickedIndexes = [];
          chart.value.update();
        }
      });
    }
    const clickEventPlugin = {
      id: "clickEventPlugfin",
      afterInit(chart) {
        chart.options.onClick = (ev, element) => {
          if (element) {
            const indexClicked = element[0]?.index;
            const datasets = ev.chart.data.datasets;
            const label = ev.chart.data?.labels;
            datasets.forEach((dataset) => {
              let currentColors = dataset.backgroundColor;
              let currentBorders = dataset.borderWidth;
              let currentColorBorders = dataset.borderColor;
              if (typeof dataset.backgroundColor === "function") {
                currentColors = dataset.backgroundColor(ev);
                currentBorders = dataset.borderWidth(ev);
                currentColorBorders = dataset.borderColor(ev);
              }
              if (Array.isArray(currentColors) && currentColors[indexClicked]) {
                if (!dataset.clickedIndexes) {
                  dataset.clickedIndexes = [];
                  dataset.originalColors = currentColors.slice();
                  dataset.originalborderWidth = currentBorders.slice();
                  dataset.originalBorderColors = currentColorBorders.slice();
                }
                const isClicked = dataset.clickedIndexes.includes(indexClicked);

                if (isClicked) {
                  currentColors[indexClicked] =
                    dataset.originalColors[indexClicked];
                  currentBorders[indexClicked] =
                    dataset.originalborderWidth[indexClicked];
                  currentColorBorders[indexClicked] =
                    dataset.originalBorderColors[indexClicked];
                  dataset.clickedIndexes = dataset.clickedIndexes.filter(
                    (idx) => idx !== indexClicked
                  );
                } else {
                  currentColors[indexClicked] = backgroundColorWithOpacity(
                    currentColors[indexClicked],
                    1,
                    125
                  );
                  currentBorders[indexClicked] =
                    currentBorders[indexClicked] + 1.6;
                  currentColorBorders[indexClicked] =
                    props.allConfigComponent.stateMode == "light"
                      ? "black"
                      : "white";
                  dataset.clickedIndexes.push(indexClicked);
                }
                dataset.backgroundColor = () => currentColors;
                dataset.borderWidth = () => currentBorders;
                dataset.borderColor = () => currentColorBorders;
                indexSelect.value = dataset.clickedIndexes;
                ev.chart.update();
              }
            });
            filtroHighLight.value = [];
            const valores = label
              .filter((_a, i) => {
                return indexSelect.value.includes(i);
              })
              .map((value) => value);
            const filter = {
              valores_parametro: valores,
              parametro: ev.chart.data.nome,
              id_consulta: props.allConfigComponent.id_consulta,
            };
            filtroHighLight.value.push(filter);
            localStorage.setItem(
              "filter_chart_dicamic",
              JSON.stringify(filtroHighLight.value)
            );
          }
          /*  emit('sendElementChartHasFiltred',
             props.allConfigComponent.instance,
             filtroHighLight,
             props.allConfigComponent.id) */
        };
      },
    };

    return {
      htmlLegendPlugin,
      clickEventPlugin,
      selectedData,
      Charts,
      backgroundColorWithOpacity,
      selected,
      indexSelected,
      searchonTable,
      activeSearhTable,
      dataFiltered,
      chartHeight,
      extractIndexPagination,
      resetStyleChart,
      indexSelect,
      filtroHighLight,
      chartElementWidth,
      Width,
      pageChartSelectWidth,
      titleChartWidth,
    };
  },

  computed: {
    filters() {
      return this.$store.state.chartConfig.filterOptions;
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", () => {
      this.Width;
      this.handleResize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.handleResize();
    });
  },
  methods: {
    handleResize() {
      this.updateChartHeight();
    },
    updateChartHeight() {
      const cssValue = `calc(100% - ${this.selectedData * 45}px)`;
      this.Width();
      if (
        window.matchMedia("(max-width: 990px)").matches ||
        this.titleChartWidth + this.pageChartSelectWidth >
          this.chartElementWidth
      ) {
        this.chartHeight = cssValue;
      } else {
        this.chartHeight = `inherit`;
      }
    },
  },
};
</script>
<template>
  <div class="container-fluid h-100">
    <div
      class="card card-animate d-flex flex-column p-0 m-0"
      :style="{
        backgroundColor: '',
        border: '1px solid ' + allConfigComponent.cor_borda,
        height: 'inherit',
      }"
    >
      <div class="card-body h-100 p-0">
        <template v-if="allConfigComponent">
          <!-- CARDS -->
          <div
            id="carrd"
            class="h-100 w-100 m-0"
            v-if="allConfigComponent.id_type == 1"
          >
            <div
              class="position-absolute h-100 start m-0 p-0"
              style="z-index: 1"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 120"
                style="height: 100%; width: 100%"
              >
                <path
                  id="Shape"
                  class="w-100 h-100"
                  stroke-width="1.7"
                  :stroke="
                    backgroundColorWithOpacity(
                      allConfigComponent.cor_borda,
                      0.4,
                      allConfigComponent.statemode == 'light' ? 30 : -30
                    )
                  "
                  :style="{
                    fill: backgroundColorWithOpacity(
                      allConfigComponent.cor_borda,
                      0.04
                    ),
                  }"
                  d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                ></path>
              </svg>
            </div>
            <div style="z-index: 2">
              <div class="d-flex align-items-center p-2">
                <div class="avatar-xs m-0 flex-shrink-0">
                  <span
                    class="avatar-title m-0 rounded fs-3"
                    :style="
                      'background-color: ' +
                      backgroundColorWithOpacity(
                        allConfigComponent.cor_borda,
                        0.3
                      )
                    "
                  >
                    <i
                      :class="allConfigComponent.icone + ''"
                      :style="'color:' + allConfigComponent.cor_borda"
                    ></i
                  ></span>
                </div>
                <div class="flex-grow-1 ps-3">
                  <h5 class="text-muted text-uppercase fs-13 mb-0">
                    {{ allConfigComponent.titulo }}
                  </h5>
                </div>
              </div>
              <div
                v-if="allConfigComponent.acelerometro"
                id="graficocard"
                class="position-absolute m-0 p-0"
                style="z-index: 100; width: 90%; height: 70%"
              >
                <vue3-chart-js
                  :key="Date.now()"
                  :id="allConfigComponent.id"
                  :type="allConfigComponent.acelerometro.type"
                  :data="allConfigComponent.acelerometro.data"
                  :ref="allConfigComponent.acelerometro.ref"
                  :options="allConfigComponent.acelerometro.options"
                ></vue3-chart-js>
              </div>
              <div
                v-else
                id="valorcard"
                class="d-flex justify-content-between m-0 p-0 w-100 h-100"
              >
                <div class="mt-2 pt-1">
                  <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                    {{ allConfigComponent.valores[0].prefix }}
                    <span>
                      {{
                        allConfigComponent.valores[0].valor.toLocaleString(
                          "pt-BR",
                          {
                            minimumFractionDigits:
                              allConfigComponent.valores[0].casadec,
                            maximumFractionDigits:
                              allConfigComponent.valores[0].casadec,
                          }
                        )
                      }}
                    </span>
                    {{ allConfigComponent.valores[0].suffix }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <!-- GRAFICOS -->

          <div
            :class="['m-0 p-2', 'chart-all' + allConfigComponent.id]"
            :style="{ height: '93%', width: '100%' }"
            v-else-if="allConfigComponent.id_type !== 6"
          >
            <div
              :class="[
                'd-flex flex-column flex-lg-row gap-2 justify-content-between title-chart',
                titleChartWidth + pageChartSelectWidth > chartElementWidth
                  ? 'flex-lg-column  '
                  : 'flex-lg-row',
              ]"
            >
              <div
                :class="[
                  'align-items-center me-auto',
                  'title-chart-dashboard' + allConfigComponent.id,
                ]"
              >
                <div class="d-flex">
                  <div class="avatar-xs m-0 flex-shrink-0">
                    <span
                      class="avatar-title m-0 rounded fs-3"
                      :style="
                        'background-color: ' +
                        backgroundColorWithOpacity(
                          allConfigComponent.cor_borda,
                          0.3
                        )
                      "
                    >
                      <i
                        :class="allConfigComponent.icone + ''"
                        :style="'color:' + allConfigComponent.cor_borda"
                      ></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 align-self-center ps-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">
                      {{ allConfigComponent.titulo }}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                v-if="allConfigComponent.mydata.length > 1"
                :class="[
                  'group-selectedFilterChart' + allConfigComponent.id,
                  ' d-block  m-0 d-lg-flex align-items-center align-content-center justify-content-between',
                  titleChartWidth + pageChartSelectWidth > chartElementWidth
                    ? 'flex-lg-column mx-auto'
                    : 'flex-lg-row',
                ]"
              >
                <div
                  v-for="(item, idx) in allConfigComponent.paginacao[0]"
                  :key="idx"
                  class="mb-2"
                >
                  <Multiselect  autocomplete="one-time-code" 
                    class="selectedFilterChart"
                    v-model="selected[idx]"
                    style="max-width: 210px; max-height: 38px"
                    @select="
                      () => {
                        extractIndexPagination();
                        updateChartHeight();
                      }
                    "
                    :options="[
                      ...new Set(
                        allConfigComponent.paginacao.map((p) => p[idx])
                      ),
                    ]"
                    :default="allConfigComponent.paginacao[0]"
                    :multiple="false"
                    :searchable="false"
                    :mode="'single'"
                    :canDeselect="false"
                    :canClear="false"
                    :allowEmpty="false"
                    :classes="{
                      wrapper:
                        'w-100 relative  mx-0 gap-0 d-flex align-items-center justify-content-between box-border cursor-pointer outline-none',
                      singleLabel: 'p-2 w-100 text-left overflow-hidden',
                    }"
                    @click.stop
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
            <div
              :style="{ height: chartHeight }"
              class="w-100 chart-container-dashboard"
            >
              <vue3-chart-js
                :key="Date.now()"
                :ref="(a) => (Charts = a)"
                :type="allConfigComponent.type"
                :id="allConfigComponent.id"
                class="chart-vue3"
                :data="allConfigComponent.mydata[indexSelected].data"
                :options="allConfigComponent.mydata[indexSelected].options"
                :plugins="[htmlLegendPlugin, clickEventPlugin]"
              ></vue3-chart-js>
            </div>
          </div>
        </template>
        <!-- TABELAS -->
        <div class="m-0 p-2" v-if="allConfigComponent.id_type == 6">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="avatar-xs m-0 flex-shrink-0">
                <span
                  class="avatar-title m-0 rounded fs-3"
                  :style="
                    'background-color: ' +
                    backgroundColorWithOpacity(
                      allConfigComponent.cor_borda,
                      0.3
                    )
                  "
                >
                  <i
                    :class="allConfigComponent.icone + ''"
                    :style="'color:' + allConfigComponent.cor_borda"
                  ></i>
                </span>
              </div>
              <div class="flex-grow-1 ps-3">
                <h5 class="text-muted text-uppercase fs-13 mb-0">
                  {{ allConfigComponent.titulo }}
                </h5>
              </div>
            </div>
            <div>
              <vue3-tags-input
                :add-tag-on-keys="[13]"
                placeholder="Filtrar"
                :tags="searchonTable"
                @on-tags-changed="activeSearhTable"
              ></vue3-tags-input>
            </div>
          </div>
        </div>
        <div
          v-if="allConfigComponent.id_type == 6"
          class="m-0 gridjs-dashboard"
          :style="{
            height: allConfigComponent.id_type == 6 ? 'calc(88% - 24px)' : '0%',
            padding: '3px',
          }"
          :id="allConfigComponent.id"
        ></div>
      </div>
    </div>
  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.gridjs-dashboard .gridjs-thead {
  position: sticky;
  top: 0;
}

.gridjs-dashboard .gridjs-th-content {
  text-align: center;
}

.gridjs-dashboard .gridjs-table {
  table-layout: fixed;
}

.gridjs-dashboard .gridjs-wrapper {
  max-height: -webkit-fill-available;
}

.gridjs-dashboard .gridjs-footer {
  padding: 0;
  margin: 0;
  padding-left: 3px;
  display: grid;
  justify-content: center;
}

@media screen and (max-width: 990) {
  .selectedFilterChart {
    max-height: 26px;
  }

  .group-selectedFilterChart {
    width: auto;
  }
}
</style>
